const LocationData = [
    {
        'City': 'Anaheim',
        'State': 'CA',
        'Id': 'garagedooranaheim.org',
        'Url': 'https://www.garagedooranaheim.org',
        'Phone': '951-517-3112'
    },
    {
        'City': 'Calabasas',
        'State': 'CA',
        'Id': 'garagedoorcalabasas.org',
        'Url': 'https://www.garagedoorcalabasas.org',
        'Phone': '747-302-2442'
    },
    {
        'City': 'Chino',
        'State': 'CA',
        'Id': 'garagedoorchino.org',
        'Url': 'https://www.garagedoorchino.org',
        'Phone': '657-255-8745'
    },
    {
        'City': 'Corona',
        'State': 'CA',
        'Id': 'garagedoorcorona.org',
        'Url': 'https://www.garagedoorcorona.org',
        'Phone': '951-517-2624'
    },
    {
        'City': 'Anaheim',
        'State': 'CA',
        'Id': 'garagedooranaheim.org',
        'Url': 'https://www.garagedooranaheim.org',
        'Phone': '951-612-9362'
    },
    {
        'City': 'Glendale',
        'State': 'CA',
        'Id': 'garagedoorglendale.org',
        'Url': 'https://www.garagedoorglendale.org',
        'Phone': '747-297-8494'
    },
    {
        'City': 'Huntington Beach',
        'State': 'CA',
        'Id': 'garagedoorhuntingtonbeach.org',
        'Url': 'https://www.garagedoorhuntingtonbeach.org',
        'Phone': '657-258-2617'
    },
    {
        'City': 'Irvine',
        'State': 'CA',
        'Id': 'garagedoorirvine.org',
        'Url': 'https://www.garagedoorirvine.org',
        'Phone': '949-776-9254'
    },
    {
        'City': 'Laguna Niguel',
        'State': 'CA',
        'Id': 'garagedoorlagunaniguel.org',
        'Url': 'https://www.garagedoorlagunaniguel.org',
        'Phone': '949-779-3483'
    },
    {
        'City': 'Long Beach',
        'State': 'CA',
        'Id': 'garagedoorlongbeach.org',
        'Url': 'https://www.garagedoorlongbeach.org',
        'Phone': '562-582-7589'
    },
    {
        'City': 'Moreno Valley',
        'State': 'CA',
        'Id': 'garagedoormorenovalley.org',
        'Url': 'https://www.garagedoormorenovalley.org',
        'Phone': '951-499-1642'
    },
    {
        'City': 'Ontario',
        'State': 'CA',
        'Id': 'garagedoorontario.org',
        'Url': 'https://www.garagedoorontario.org',
        'Phone': '657-286-2385'
    },
    {
        'City': 'Oxnard',
        'State': 'CA',
        'Id': 'garagedooroxnard.org',
        'Url': 'https://www.garagedooroxnard.org',
        'Phone': '805-694-0087'
    },
    {
        'City': 'Pasadena',
        'State': 'CA',
        'Id': 'garagedoorpasadena.org',
        'Url': 'https://www.garagedoorpasadena.org',
        'Phone': '213-513-7853'
    },
    {
        'City': 'Pomona',
        'State': 'CA',
        'Id': 'garagedoorpomona.org',
        'Url': 'https://www.garagedoorpomona.org',
        'Phone': '951-499-5808'
    },
    {
        'City': 'Rancho Cucamonga',
        'State': 'CA',
        'Id': 'garagedoorranchocucamonga.org',
        'Url': 'https://www.garagedoorranchocucamonga.org',
        'Phone': '951-501-4107'
    },
    {
        'City': 'Redlands',
        'State': 'CA',
        'Id': 'garagedoorredlands.org',
        'Url': 'https://www.garagedoorredlands.org',
        'Phone': '951-720-4169'
    },
    {
        'City': 'Rialto',
        'State': 'CA',
        'Id': 'garagedoorrialto.org',
        'Url': 'https://www.garagedoorrialto.org',
        'Phone': '951-709-0114'
    },
    {
        'City': 'Riverside',
        'State': 'CA',
        'Id': 'garagedoorriverside.org',
        'Url': 'https://www.garagedoorriverside.org',
        'Phone': '951-612-2216'
    },
    {
        'City': 'San Bernardino',
        'State': 'CA',
        'Id': 'garagedoorsanbernardino.org',
        'Url': 'https://www.garagedoorsanbernardino.org',
        'Phone': '760-965-3819'
    },
    {
        'City': 'San Clemente',
        'State': 'CA',
        'Id': 'garagedoorsanclemente.org',
        'Url': 'https://www.garagedoorsanclemente.org',
        'Phone': '949-806-3488'
    },
    {
        'City': 'Santa Ana',
        'State': 'CA',
        'Id': 'garagedoorsantaana.org',
        'Url': 'https://www.garagedoorsantaana.org',
        'Phone': '949-676-8424'
    },
    {
        'City': 'Santa Clarita',
        'State': 'CA',
        'Id': 'garagedoorsantaclarita.org',
        'Url': 'https://www.garagedoorsantaclarita.org',
        'Phone': '661-485-4428'
    },
    {
        'City': 'Santa Monica',
        'State': 'CA',
        'Id': 'garagedoorsantamonica.org',
        'Url': 'https://www.garagedoorsantamonica.org',
        'Phone': '213-642-2179'
    },
    {
        'City': 'Simi Valley',
        'State': 'CA',
        'Id': 'garagedoorsimivalley.org',
        'Url': 'https://www.garagedoorsimivalley.org',
        'Phone': '805-691-7042'
    },
    {
        'City': 'Temecula',
        'State': 'CA',
        'Id': 'garagedoortemecula.org',
        'Url': 'https://www.garagedoortemecula.org',
        'Phone': '951-556-6295'
    },
    {
        'City': 'Thousand Oaks',
        'State': 'CA',
        'Id': 'garagedoorthousandoaks.org',
        'Url': 'https://www.garagedoorthousandoaks.org',
        'Phone': '805-977-5620'
    },
    {
        'City': 'Torrance',
        'State': 'CA',
        'Id': 'garagedoortorrance.org',
        'Url': 'https://www.garagedoortorrance.org',
        'Phone': '424-404-6606'
    },
    {
        'City': 'Ventura',
        'State': 'CA',
        'Id': 'garagedoorventura.org',
        'Url': 'https://www.garagedoorventura.org',
        'Phone': '805-635-0515'
    }
]
export default LocationData;
